import React from 'react'
import { graphql } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import {
  Flex,
  Box,
  Heading,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
} from '@chakra-ui/react'
import { kebabCase } from 'lodash'

import Layout from '../components/layout/Layout'
import Container from '../components/layout/Container'
import Section from '../components/layout/Section'
import SEO from '../components/elements/SEO'
import Hero from '../components/elements/Hero'
import Book from '../components/elements/Book'
import CloudsBanner from '../components/elements/CloudsBanner'

const FlightsPage = ({ data }) => {
  const page = data.prismicFlightspage

  return (
    <Layout>
      <SEO
        meta_title={page.data.meta_title}
        meta_description={page.data.meta_description}
      />

      <CloudsBanner>
        <Heading as="h1" mx="auto" width={['full', 'full', 3 / 4]}>
          Daily flights to Chicago O’Hare International Airport (ORD) and Washington Dulles Airport (IAD)
        </Heading>
      </CloudsBanner>

      <Hero>
        <BackgroundImage
          Tag="div"
          fluid={page.data.hero_image.fluid}
          css={`
            padding-top: 180px;
          `}
        >
          <Container
            py={['12em', '12em', '16em']}
            css={`
              z-index: 20;
              display: block;
              position: relative;
            `}
          />
        </BackgroundImage>
      </Hero>

      <Section id="intro">
        <Container>
          <Flex flexWrap="wrap" mx={-8} alignItems="center">
            <Box width={['full', 'full', 2 / 3]} px={8} mb={[8, 8, 0]}>
              <Text
                dangerouslySetInnerHTML={{
                  __html: page.data.content.html,
                }}
              />
            </Box>
            <Box width={['full', 'full', 1 / 3]} px={8} color="white">
              {page.data.rates.map(
                ({ from: departure, to: destination, rate }) => (
                  <Box
                    key={rate.text}
                    borderBottomWidth={4}
                    borderBottomColor="white"
                    bg="primary"
                    fontSize="xl"
                    fontWeight="600"
                  >
                    <Flex justifyContent="space-between" p={8}>
                      <Box width="full">
                        {departure} <span>&#x27F6;</span> {destination}
                      </Box>{' '}
                      <Box>{rate.text}</Box>
                    </Flex>
                  </Box>
                )
              )}
            </Box>
          </Flex>
        </Container>
      </Section>

      <Section id="schedule" bg="gray.50">
        <Container>
          <Flex flexWrap="wrap" justifyContent="center">
            <Box width="full" textAlign="center">
              <Heading as="h2" mb={1}>
                Flight Schedule
              </Heading>
              <p>(as of {page.last_publication_date})</p>
            </Box>

            {data.allPrismicFlightspageBodySchedule.edges.map(
              ({ node: schedule }) => (
                <Box
                  key={`${kebabCase(schedule.primary.day_range.text)}`}
                  width={['full', 'full', 3 / 4]}
                >
                  <Box as="h3" textAlign="center">
                    {schedule.primary.day_range.text}
                  </Box>
                  <Table variant="striped" bg="white" my={6}>
                    <Thead>
                      <Tr>
                        <Th>Flight No.</Th>
                        <Th>From</Th>
                        <Th>To</Th>
                        <Th>Departs</Th>
                        <Th>Arrives</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {schedule.items.map(
                        ({
                          flight_no,
                          from,
                          to,
                          departure_time,
                          arrival_time,
                        }) => (
                          <Tr
                            key={`${kebabCase(
                              `${flight_no.text}${schedule.primary.day_range.text}`
                            )}`}
                          >
                            <Td>{flight_no.text}</Td>
                            <Td>{from}</Td>
                            <Td>{to}</Td>
                            <Td>{departure_time.text}</Td>
                            <Td>{arrival_time.text}</Td>
                          </Tr>
                        )
                      )}
                    </Tbody>
                  </Table>
                </Box>
              )
            )}
          </Flex>
        </Container>
      </Section>
      <Book />
    </Layout>
  )
}

export default FlightsPage

export const query = graphql`
  query FlightsPage {
    prismicFlightspage {
      data {
        meta_description
        meta_title
        content {
          html
        }
        title {
          text
        }
        hero_image {
          url
          alt
          fluid(maxWidth: 1500) {
            ...GatsbyPrismicImageFluid
          }
        }
        hero_text {
          text
        }
        rates {
          from
          to
          rate {
            text
          }
        }
        body {
          ... on PrismicFlightspageBodySchedule {
            id
            items {
              from
              to
            }
          }
        }
      }
      last_publication_date(formatString: "MMM DD, YYYY")
    }
    allPrismicFlightspageBodySchedule {
      edges {
        node {
          primary {
            day_range {
              text
            }
          }
          items {
            flight_no {
              text
            }
            from
            to
            departure_time {
              text
            }
            arrival_time {
              text
            }
          }
        }
      }
    }
  }
`
