import React from 'react'
import { Section, Container  } from '../layout'
import { Box, Heading, SimpleGrid, Text } from '@chakra-ui/react'



function Book(props) {
  return (
    <Section id="booking" bg="gray.900" sx={{ '*': { color: 'white' }, 'img':{ width: '100%', marginTop: 0, marginBottom: 0 } }}>
      <Container>
        <SimpleGrid columns={[1, 1, 2]} spacing={9}>
          <Box sx={{alignItems: 'center'}}>
            <Box as="h2">Book a Flight</Box>
            <Box as="p" sx={{ 'fontSize': ['lg', 'lg', 'xl']}}>Reaching your destination is fast and easy. Book your flight with us today! For tickets and domestic and international flights information, <a href="https://www.united.com/">visit United.com</a></Box>
          </Box>

          <img src='https://images.prismic.io/morgantownairport/Z1sKx5bqstJ98awg_morg-airport-united.jpg?auto=format,compress' alt='Book your flights to Washington, DC and Chicago' />
        </SimpleGrid>
      </Container> 
    </Section>
  )
}

export default Book